import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 公共路由
export const constantRoutes = [
    {
        path: '/',
        component: (resolve) => require(['@/views/index'], resolve),
        hidden: true
    },
    {
        path: '/download',
        name: 'download',
        component: (resolve) => require(['@/views/download'], resolve),
        hidden: true
    },
]

export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})
